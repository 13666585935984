import React, { useEffect, useState } from "react";
import { FiChevronsLeft } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addPilotChampionship, getCampeonatos } from "../../api";
import "./modal.css";

export function Modal({ piloto, close }) {

  const [championships, setChampionships] = useState([]);

  const [form, setForm] = useState({
    id: "",
  });

    const setAllChampionships = async () => {
      const response = await getCampeonatos();
      setChampionships(response.data);
      setForm((prevForm) => ({
        ...prevForm,
        id: response.data[0]._id,
      }));
    };
  
    useEffect(() => {
      setAllChampionships();
    }, []);
  
  const cadastrar = async (event) => {
    event.preventDefault();
    const { nome, numero, notas, pontuacao } = piloto;
    const id = form.id;
    try {
      await addPilotChampionship({
        id,
        nome,
        numero,
        notas,
        pontuacao,
      });
      toast.success("Piloto adicionado ao Campeonato!");
      setTimeout(() => {
        window.location.reload();
      }, 3000); // 3000 milissegundos = 3 segundos
    } catch (error) {
      toast.error("Erro ao adicionar o Piloto ao Campeonato, tente novamente.");
    }
  };

  return (
    <div>
      <div className="modal">
        <div className="container">
          <button className="close" onClick={close}>
            <FiChevronsLeft size={25} color="#FFF" />
            Voltar
          </button>

          <main>
            <h2>
              Em qual campeonato deseja adicionar o piloto {piloto?.nome}?
            </h2>
            <form className="form-profile" onSubmit={cadastrar}>
              <label> Campeonato desejado: </label>
              <select
                name="id"
                value={form.id}
                onChange={(e) =>
                  setForm((prevForm) => ({
                    ...prevForm,
                    id: e.target.value,
                  }))
                }
              >
                {championships.map((championship) => (
                  <option key={championship?._id} value={championship?._id}>
                    {championship?.nome} - Temporada: {championship?.seasion}
                  </option>
                ))}
              </select>
              <button className="submit" type="subimit">
                Cadastrar
              </button>
            </form>
          </main>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
