import axios from "axios";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const api = axios.create({
    baseURL: "https://fd-backend-5wyk.onrender.com",
});

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem("Token");
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
        setTimeout(() => {
            history.push("/login");
            window.location.reload();
        }, 3000); // 3000 milissegundos = 3 segundos
        }
        return Promise.reject(error);
    }
);

export const login = async (username, password) => {
    const formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);

    try {
        const response = await api.post("/login", formData);
        return response.data;
    } catch (error) {
        throw error; 
    }
};

export const createPiloto = async ({ nome, numero, notas, pontuacao }) => 
    api.post("/piloto", {
        nome,
        numero,
        notas,
        pontuacao,
    });
    
export const createCampeonato = async ({ nome, seasion, classificacao, status, tipo }) =>
    api.post("/campeonato", {
        nome,
        seasion,
        classificacao,
        status,
        tipo,
    });

export const addPilotChampionship = async (object) =>
    api.post(`/campeonatos/${object.id}/pilotos/`, {
        nome: object.nome,
        numero: object.numero,
        notas: object.notas,
        pontuacao: object.pontuacao,
    });

export const updatePilotChampionship = async (
    nomePiloto,
    idCampeonato,
    { novasNotas }
    ) => {
    console.log(nomePiloto);
        api.put(
        `/campeonatos/${idCampeonato}/pontuacao/?nome_piloto=${nomePiloto}`,
        {
            novas_notas: novasNotas,
        }
    );
};
    
export const getPilotos = async () => api.get("/pilotos");

export const getPiloto = async (nomePiloto) => api.get(`/piloto/${nomePiloto}`);

export const getCampeonatos = async () => api.get("/campeonato/");

export const getRanking = async (idCampeonato) =>
    api.get(`/campeonatos/${idCampeonato}/classificacao`);