import React, { useEffect, useState } from "react";
import { FiChevronsLeft } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCampeonatos, updatePilotChampionship } from "../../api";

export function ModalUpdate({ piloto, close }) {
    const [championships, setChampionships] = useState([]);

    const [form, setForm] = useState({
        id: "",
        notas: {
            notaEstilo: 0,
            notaLinha: 0,
            notaAngulo: 0,
        }
    });

    const setAllChampionships = async () => {
        const response = await getCampeonatos();
        setChampionships(response.data);
        setForm((prevForm) => ({
        ...prevForm,
        id: response.data[0]._id,
        }));
    };

    useEffect(() => {
        setAllChampionships();
    }, []);

    const cadastrar = async (event) => {
        event.preventDefault();
        const nomePiloto = piloto.nome;
        const { id, notas} = form;
        try {
          await updatePilotChampionship(nomePiloto, id, {
            novasNotas: [notas.notaEstilo, notas.notaLinha, notas.notaAngulo],
          });
          toast.success("Notas Atualizadas!");
          setTimeout(() => {
            window.location.reload();
          }, 3000); // 3000 milissegundos = 3 segundos
        } catch (error) {
        toast.error("Erro ao atualizar as notas, tente novamente.");
        }
    };

    return (
        <div>
            <div className="modal">
            <div className="container">
                <button className="close" onClick={close}>
                <FiChevronsLeft size={25} color="#FFF" />
                Voltar
                </button>

                <main>
                <h2>
                    Em qual campeonato deseja adicionar o piloto {piloto?.nome}?
                </h2>
                <form className="form-profile-modal-update" onSubmit={cadastrar}>
                    <div>
                    <label> Campeonato desejado: </label>
                    <select
                        name="id"
                        value={form.id}
                        onChange={(e) =>
                        setForm((prevForm) => ({
                            ...prevForm,
                            id: e.target.value,
                        }))
                        }
                    >
                        {championships.map((championship) => (
                        <option key={championship?._id} value={championship?._id}>
                            {championship?.nome} - Temporada:{" "}
                            {championship?.seasion}
                        </option>
                        ))}
                    </select>
                    </div>
                    <div>
                    <label>Nota Estilo:</label>
                    <input
                        type="number"
                        id="notaEstilo"
                        value={form.notas.notaEstilo}
                        onChange={(e) =>
                        setForm((prevForm) => ({
                            ...prevForm,
                            notas: {
                            ...prevForm.notas,
                            notaEstilo: e.target.value,
                            },
                        }))
                        }
                        required
                    />
                    <label>Nota Linha:</label>
                    <input
                        type="number"
                        id="notaLinha"
                        value={form.notas.notaLinha}
                        onChange={(e) =>
                        setForm((prevForm) => ({
                            ...prevForm,
                            notas: {
                            ...prevForm.notas,
                            notaLinha: e.target.value,
                            },
                        }))
                        }
                        required
                    />
                    <label>Nota Ângulo:</label>
                    <input
                        type="number"
                        id="notaAngulo"
                        value={form.notas.notaAngulo}
                        onChange={(e) =>
                        setForm((prevForm) => ({
                            ...prevForm,
                            notas: {
                            ...prevForm.notas,
                            notaAngulo: e.target.value,
                            },
                        }))
                        }
                        required
                    />
                    </div>
                    <button className="submit" type="subimit">
                    Cadastrar
                    </button>
                </form>
                </main>
            </div>
            </div>
            <ToastContainer />
        </div>
    );
}
