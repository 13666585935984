import React, { useEffect, useState } from "react";
import { getCampeonatos, getRanking } from "../../api";
import FDLogo from "../../assets/FD_sem_fundo.png";
import StreetLogo from "../../assets/SCCORIGEN2.png";
import "./classificacao.css"; // Importar o CSS

function ClassificacaoPage() {
    const [classificacao, setClassificacao] = useState([]);

    const setAllChampionships = async () => {
        const response = await getCampeonatos();
        await fetchRanking(response.data[0]?._id);
    };

    const fetchRanking = async (id) => {
        try {
            const data = await getRanking(id);
            setClassificacao(data.data.classificacao);
        } catch (error) {
            console.error("Erro ao buscar pilotos:", error);
        }
    };

    useEffect(() => {
        setAllChampionships();
    }, []);

    return (
      <div>
        <div className="divLogos">
          <a href="/login">
            <img
              className="logos"
              src={StreetLogo}
              alt="Logo Street Car Club"
            />
          </a>
          <h1>Classificação</h1>
          <a href="/pilotos">
            <img className="logos" src={FDLogo} alt="Logo Formula Drift" />
          </a>
        </div>
        <div className="table-container">
          <table className="classificacao">
            <thead>
              <tr>
                <th className="classificacao-th colocacao">#</th>
                <th className="classificacao-th">Nome</th>
                <th className="classificacao-th pontos">Pontos</th>
              </tr>
            </thead>
            <tbody className="classificacao-tbody">
              {classificacao.slice(0, 32).map((item, index) => (
                <tr
                  key={index}
                  className={
                    index < 15
                      ? "top16"
                      : index === 15
                      ? "top16 line-between"
                      : ""
                  }
                >
                  <td className="classificacao-td">{index + 1}</td>
                  <td className="classificacao-td">
                    #{item.numero} {item.nome}
                  </td>
                  <td className="classificacao-td">
                    {item.pontuacao.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
}

export default ClassificacaoPage;
