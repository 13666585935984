import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../api";
import FDLogo from "../../assets/FD_sem_fundo_II.png";
import StreetLogo from "../../assets/street24logoprt.png";
import "./login.css";

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = await login(username, password);
            localStorage.setItem("Token", data.access_token);
            navigate("/pilotos");
        } catch (error) {
        toast.error("Usuário e/ou senha incorretos.");
        }
    };

    return (
      <div>
        <div className="login-container">
          <div>
            <img
              className="logos-completos"
              src={StreetLogo}
              alt="Logo Street Car Club"
            />
            <img
              className="logos-completos"
              src={FDLogo}
              alt="Logo Formula Drift"
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
        </div>
        <ToastContainer />
      </div>
    );
}

export default LoginPage;
