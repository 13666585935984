import React, { useEffect, useState } from "react";
import { getPilotos } from "../../api"; // Certifique-se de importar corretamente a função
import FDLogo from "../../assets/FD_sem_fundo.png";
import StreetLogo from "../../assets/SCCORIGEN2.png";
import Header from "../../components/Header";
import { Modal } from "../../components/Modal";
import { ModalUpdate } from "../../components/ModalUpdate";

import "./pilotos.css";

function PilotosPage() {
    const [pilotos, setPilotos] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
    const [showPostUpdateModal, setShowPostUpdateModal] = useState(false);

    const [detail, setDetail] = useState();

    useEffect(() => {
        fetchPilotos();
    }, []);

    const fetchPilotos = async () => {
        try {
        const data = await getPilotos();
            setPilotos(data.data.pilotos.pilotos);
            console.log(data.data.pilotos.pilotos);
        } catch (error) {
        console.error("Erro ao buscar pilotos:", error);
        }
    };

    function toggleModal(piloto) {
    setShowPostModal(!showPostModal);
    setDetail(piloto);
    }
  
   function toggleUpdateModal(piloto) {
     setShowPostUpdateModal(!showPostModal);
     setDetail(piloto);
   }

    return (
      <div>
        <Header />
        <div className="divLogos">
          <img className="logos" src={StreetLogo} alt="Logo Street Car Club" />
          <h1>Listagem dos Pilotos</h1>
          <img className="logos" src={FDLogo} alt="Logo Formula Drift" />
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th className="pontos" scope="col">
                  Número
                </th>
                <th scope="col">Nome</th>
                <th className="btn" scope="col">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              {pilotos.map((piloto) => (
                <tr key={piloto.numero}>
                  <td data-label="numero">#{piloto.numero}</td>
                  <td data-label="Nome">{piloto.nome}</td>
                  <td className="btns">
                    <button
                      className="btn-active"
                      onClick={() => toggleModal(piloto)}
                    >
                      Adicionar
                    </button>
                    <br />
                    <button
                      className="btn-active"
                      onClick={() => toggleUpdateModal(piloto)}
                    >
                      Dar Notas
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showPostModal && (
          <Modal
            piloto={detail}
            close={() => setShowPostModal(!showPostModal)}
          />
        )}

        {showPostUpdateModal && (
          <ModalUpdate
            piloto={detail}
            close={() => setShowPostUpdateModal(!showPostUpdateModal)}
          />
        )}
      </div>
    );
}

export default PilotosPage;
