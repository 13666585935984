import { Link } from "react-router-dom";

import { FiGrid, FiList, FiUsers } from "react-icons/fi";
import "./header.css";

export default function Header() {
  return (
    <div className="header">
      <Link to="/pilotos">
        <FiUsers color="#FFF" size={24} />
        Listagem de Pilotos
      </Link>
      <Link to="/campeonatos">
        <FiGrid color="#FFF" size={24} />
        Listagem de Campeonatos
      </Link>
      <Link to="/novo/piloto">
        <FiUsers color="#FFF" size={24} />
        Adicionar Pilotos
      </Link>
      <Link to="/novo/campeonato">
        <FiGrid color="#FFF" size={24} />
        Criar Campeonatos
      </Link>
      <Link to="/">
        <FiList color="#FFF" size={24} />
        Classificação
      </Link>
      <Link to="/historico">
        <FiList color="#FFF" size={24} />
        Historico de Etapas
      </Link>
    </div>
  );
}
