import React, { useEffect, useState } from "react";
import { getCampeonatos } from "../../api"; // Certifique-se de importar corretamente a função
import Header from "../../components/Header";

function HistoricoPage() {
    const [campeonatos, setCampeonatos] = useState([]);
    const [selectedCampeonatoId, setSelectedCampeonatoId] = useState(null);

    useEffect(() => {
        fetchCampeonatos();
    }, []);

    const fetchCampeonatos = async () => {
        try {
        const data = await getCampeonatos();
        setCampeonatos(data.data);
        console.log(data.data);
        } catch (error) {
        console.error("Erro ao buscar campeonatos:", error);
        }
    };

    const toggleCampeonato = (id) => {
        // Se o campeonato clicado já está selecionado, desmarque-o (contraia).
        // Caso contrário, defina-o como o campeonato selecionado (expanda).
        if (selectedCampeonatoId === id) {
        setSelectedCampeonatoId(null);
        } else {
        setSelectedCampeonatoId(id);
        }
    };

    return (
        <div>
            <Header />
            <div className="table-container">
                {campeonatos.map((campeonato) => (
                <div key={campeonato.id}>
                <h2
                    onClick={() => toggleCampeonato(campeonato.id)}
                    style={{ cursor: "pointer" }}
                >
                    #{campeonato.seasion} - {campeonato.nome}
                </h2>
                {selectedCampeonatoId === campeonato.id && (
                    <div>
                    <div className="table-container">
                        <table className="classificacao">
                        <thead>
                            <tr>
                            <th className="classificacao-th colocacao">#</th>
                            <th className="classificacao-th">Nome</th>
                            <th className="classificacao-th pontos">Pontos</th>
                            </tr>
                        </thead>
                        <tbody className="classificacao-tbody">
                            {campeonato.classificacao
                            .slice(0, 32)
                            .map((item, index) => (
                                <tr
                                key={index}
                                className={
                                    index < 15
                                    ? "top16"
                                    : index === 15
                                    ? "top16 line-between"
                                    : ""
                                }
                                >
                                <td className="classificacao-td">{index + 1}</td>
                                <td className="classificacao-td">
                                    {item.nome} - #{item.numero}
                                </td>
                                <td className="classificacao-td">
                                    {item.pontuacao.toFixed(2)}
                                </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                )}
                </div>
            ))}
            </div>
        </div>
    );
}

export default HistoricoPage;
