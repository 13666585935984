import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TournamentBracket from "./pages/Batalhas";
import CampeonatosPage from "./pages/Campeonatos";
import ClassificacaoPage from "./pages/Classificacao";
import CadastroCampeonatos from "./pages/CreateCampeonato";
import CadastroPilotos from "./pages/CreatePiloto";
import HistoricoPage from "./pages/Historico";
import Login from "./pages/Login";
import PilotosPage from "./pages/Pilotos";


function RouterComponent() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ClassificacaoPage />}></Route>
          <Route path="/pilotos" element={<PilotosPage />}></Route>
          <Route path="/campeonatos" element={<CampeonatosPage />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/novo/piloto" element={<CadastroPilotos />}></Route>
          <Route
            path="/novo/campeonato"
            element={<CadastroCampeonatos />}
          ></Route>
          <Route path="/historico" element={<HistoricoPage />}></Route>
          <Route path="/batalhas" element={<TournamentBracket />}></Route>
        </Routes>
      </BrowserRouter>
    );
}

export default RouterComponent;
