import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createPiloto } from "../../api";
import FDLogo from "../../assets/FD_sem_fundo.png";
import StreetLogo from "../../assets/SCCORIGEN2.png";
import Header from "../../components/Header";

function CadastroPilotos() {
    const [form, setForm] = useState({
        nome: "",
        numero: "",
        notas: [],
        pontuacao: 0
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { nome, numero, notas, pontuacao} = form;
        try {
          await createPiloto({
            nome,
            numero,
            notas,
            pontuacao,
          });
          toast.success("Piloto Cadastrado!");
          setTimeout(() => {
            window.location.reload();
          }, 3000); // 3000 milissegundos = 3 segundos
        } catch (error) {
        toast.error("Erro ao cadastrar piloto, tente novamente.");
        }
    };

    return (
      <div>
        <Header />
        <div className="divLogos">
          <img className="logos" src={StreetLogo} alt="Logo Street Car Club" />
          <h1>Cadastro de Pilotos</h1>
          <img className="logos" src={FDLogo} alt="Logo Formula Drift" />
        </div>
        <div className="cadastro-container">
          <form onSubmit={handleSubmit}>
            <div>
              <label>Nome:</label>
              <input
                type="text"
                value={form.nome}
                onChange={(e) =>
                  setForm((prevForm) => ({
                    ...prevForm,
                    nome: e.target.value,
                  }))
                }
                required
              />
            </div>
            <div>
              <label>Número:</label>
              <input
                type="number"
                value={form.numero}
                onChange={(e) =>
                  setForm((prevForm) => ({
                    ...prevForm,
                    numero: e.target.value,
                  }))
                }
                required
              />
            </div>
            <button type="submit">Cadastrar Piloto</button>
          </form>
        </div>
        <ToastContainer />
      </div>
    );
}

export default CadastroPilotos;
