import React, { useEffect, useState } from "react";
import { getCampeonatos } from "../../api"; // Certifique-se de importar corretamente a função
import FDLogo from "../../assets/FD_sem_fundo.png";
import StreetLogo from "../../assets/SCCORIGEN2.png";
import Header from "../../components/Header";

function CampeonatosPage() {
  const [campeonatos, setCampeonatos] = useState([]);

  useEffect(() => {
    fetchCampeonatos();
  }, []);

  const fetchCampeonatos = async () => {
    try {
      const data = await getCampeonatos();
      setCampeonatos(data.data);
      console.log(data.data);
    } catch (error) {
      console.error("Erro ao buscar campeonatos:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="divLogos">
        <img className="logos" src={StreetLogo} alt="Logo Street Car Club" />
        <h1>Listagem dos Campeonatos</h1>
        <img className="logos" src={FDLogo} alt="Logo Formula Drift" />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="temporada" scope="col">
                Temporada
              </th>
              <th scope="col">Nome</th>
            </tr>
          </thead>
          <tbody>
            {campeonatos.map((campeonato) => (
              <tr key={campeonato.id}>
                <td data-label="temporada">#{campeonato.seasion}</td>
                <td data-label="Nome">{campeonato.nome}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CampeonatosPage;
