import {
  Match,
  SVGViewer,
  SingleEliminationBracket,
  createTheme,
} from "@g-loot/react-tournament-brackets";
import FDLogo from "../../assets/FD_sem_fundo.png";
import StreetLogo from "../../assets/SCCORIGEN2.png";

export default function App() {
  return (
    <div className="fundo">
      <div className="divLogos">
        <a href="/login">
          <img className="logos" src={StreetLogo} alt="Logo Street Car Club" />
        </a>
        <h1 className="white">Batalhas</h1>
        <a href="/pilotos">
          <img className="logos" src={FDLogo} alt="Logo Formula Drift" />
        </a>
      </div>
      <SingleElimination />
    </div>
  );
}

export const SingleElimination = () => (
  <SingleEliminationBracket
    theme={GlootTheme}
    matches={exportedSmallBracket || []}
    matchComponent={Match}
    svgWrapper={({ children, ...props }) => (
      <SVGViewer
        width={1920}
        height={1920}
        background="rgb(11, 13, 19)"
        SVGBackground="rgb(11, 13, 19)"
        {...props}
      >
        {children}
      </SVGViewer>
    )}
    onMatchClick={(match) => console.log(match)}
    onPartyClick={(match) => console.log(match)}
  />
);

const GlootTheme = createTheme({
  textColor: { main: "#000000", highlighted: "#F4F2FE", dark: "#707582" },
  matchBackground: { wonColor: "#2D2D59", lostColor: "#1B1D2D" },
  score: {
    background: {
      wonColor: `#10131C`,
      lostColor: "#10131C",
    },
    text: { highlightedWonColor: "#7BF59D", highlightedLostColor: "#FB7E94" },
  },
  border: {
    color: "#292B43",
    highlightedColor: "RGBA(152,82,242,0.4)",
  },
  roundHeader: { backgroundColor: "#3B3F73", fontColor: "#F4F2FE" },
  connectorColor: "#3B3F73",
  connectorColorHighlight: "RGBA(152,82,242,0.4)",
  svgBackground: "#0F121C",
});

const classificados = [
  "Et Bilu - Laurel C35", //0
  "Hyker Ranzo - Laurel C33", //1
  "Kauan Dallas - Mark II", //2
  "Tyller Kiosak - M3 E36", //3
  "Paulo Mendes - Corvete", //4
  "DUDU - Mustang Cobra", //5 
  "Lion Morais - Cuda", //6
  "McLovin - Mustang gt500", //7 
  "Cato Sato - S15", //8
  "Phelipe Duke - Lexus RCF", //9
  "Nyll Magnun - Challenger", ///10
  "Cj - Mark II", //11
  "Bob Jhonson - s14", //12
  "Adriano Martinez - M3 E36", //13
  "Dominic Lopes - Laurel C33", //14
  "Rafael Martins - Laurel C33" //15
];

export const exportedSmallBracket = [
  //OITAVAS 1 (QUARTAS 1)
  {
    id: "Runoff1_M3",
    nextMatchId: "M3",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[0],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[15],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  // OITAVAS 2 (QUARTAS 4)
  {
    id: "Runoff2_M3",
    nextMatchId: "M6",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[1],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[14],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  // OITAVAS 3 (QUARTAS 3)
  {
    id: "Runoff1_M4",
    nextMatchId: "M5",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[2],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[13],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  // OITAVAS 4 (QUARTAS 2)
  {
    id: "Runoff2_M4",
    nextMatchId: "M4",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[3],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[12],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  // OITAVAS 5 (QUARTAS 2)
  {
    id: "Runoff1_null_M5",
    nextMatchId: "M4",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[4],
        resultText: "Loss",
        isWinner: false,
      },
      {
        name: classificados[11],
        resultText: "Win",
        isWinner: true,
      },
    ],
  },
  // OITAVAS 6 (QUARTAS 3)
  {
    id: "Runoff2_null_M5",
    nextMatchId: "M5",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[5],
        resultText: "Loss",
        isWinner: false,
      },
      {
        name: classificados[10],
        resultText: "Win",
        isWinner: true,
      },
    ],
  },
  //OITAVAS 7 (QUARTAS 4)
  {
    id: "Runoff1_null_M6",
    nextMatchId: "M6",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[6],
        resultText: "Loss",
        isWinner: false,
      },
      {
        name: classificados[9],
        resultText: "Win",
        isWinner: true,
      },
    ],
  },
  //OITAVAS 8 (QUARTAS 1)
  {
    id: "Runoff2_null_M6",
    nextMatchId: "M3",
    tournamentRoundText: "1",
    participants: [
      {
        name: classificados[7],
        resultText: "Loss",
        isWinner: false,
      },
      {
        name: classificados[8],
        resultText: "Win",
        isWinner: true,
      },
    ],
  },
  // QUARTAS 1 (SEMI 1)
  {
    id: "M3",
    nextMatchId: "M1",
    tournamentRoundText: "2",
    participants: [
      {
        name: classificados[0],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[8],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  //QUARTAS 2 (SEMI 1)
  {
    id: "M4",
    nextMatchId: "M1",
    tournamentRoundText: "2",
    participants: [
      {
        name: classificados[3],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[11],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },

  //QUARTAS 3 (SEMI 2)
  {
    id: "M5",
    nextMatchId: "M2",
    tournamentRoundText: "2",
    participants: [
      {
        name: classificados[2],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[10],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  // QUARTAS 4 (SEMI 2)
  {
    id: "M6",
    nextMatchId: "M2",
    tournamentRoundText: "2",
    participants: [
      {
        name: classificados[1],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[9],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  //SEMI 1
  {
    id: "M1",
    nextMatchId: "M0",
    tournamentRoundText: "3",
    participants: [
      {
        name: classificados[0],
        resultText: "Win",
        isWinner: true,
      },
      {
        name: classificados[3],
        resultText: "Loss",
        isWinner: false,
      },
    ],
  },
  // SEMI 2
  {
    id: "M2",
    nextMatchId: "M0",
    tournamentRoundText: "3",
    participants: [
      {
        name: classificados[2],
        resultText: "Loss",
        isWinner: false,
      },
      {
        name: classificados[1],
        resultText: "Win",
        isWinner: true,
      },
    ],
  },
  //FINAL
  {
    id: "M0",
    nextMatchId: null,
    tournamentRoundText: "4",
    participants: [
      {
        name: classificados[0],
        resultText: "Loss",
        isWinner: false,
      },
      {
        name: classificados[1],
        resultText: "Win",
        isWinner: true,
      },
    ],
  },
];
