import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createCampeonato } from "../../api";
import FDLogo from "../../assets/FD_sem_fundo.png";
import StreetLogo from "../../assets/SCCORIGEN2.png";
import Header from "../../components/Header";


function CadastroCampeonatos() {
    const [form, setForm] = useState({
        nome: "",
        seasion: 0,
        classificacao: [],
        status: true,
        tipo: "",
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { nome, seasion, classificacao, status, tipo } = form;
        try {
            await createCampeonato({
                nome,
                seasion,
                classificacao,
                status,
                tipo,
            });
            toast.success("Campeonato Cadastrado!");
            setTimeout(() => {
            window.location.reload();
          }, 3000); // 3000 milissegundos = 3 segundos
        } catch (error) {
        toast.error("Erro ao cadastrar campeonato, tente novamente");
        }
    };

    return (
        <div>
            <Header />
            <div className="divLogos">
            <img className="logos" src={StreetLogo} alt="Logo Street Car Club" />
            <h1>Cadastro de Campeonatos</h1>
            <img className="logos" src={FDLogo} alt="Logo Formula Drift" />
            </div>
            <div className="cadastro-container">
            <form onSubmit={handleSubmit}>
                <div>
                <label>Nome:</label>
                <input
                    type="text"
                    value={form.nome}
                    onChange={(e) =>
                    setForm((prevForm) => ({
                        ...prevForm,
                        nome: e.target.value,
                    }))
                    }
                    required
                />
                </div>
                <div>
                <label>Temporada:</label>
                <input
                    type="number"
                    value={form.seasion}
                    onChange={(e) =>
                    setForm((prevForm) => ({
                        ...prevForm,
                        seasion: e.target.value,
                    }))
                    }
                    required
                />
                </div>
                <button type="submit">Cadastrar Campeonato</button>
            </form>
            <ToastContainer />
            </div>
        </div>
    );
}

export default CadastroCampeonatos;
